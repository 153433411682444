import React, { useEffect,useState } from "react";
import Api from '../../api/global.service';
import ApiUrl from '../../constants/Api';
import DataTable from 'react-data-table-component';
import estiloGeneral from '../../nuup/componentes/Tablas/estiloGeneral';
import  '../../nuup/styles/Tablas.css'
import BlockUi from 'react-block-ui';

const RiegosListadoProductores = (props) => {
	const [blocking, setBlocking] = useState(true);
	const [columns, setColumns] = useState([
		{
			name: 'Productor',
			selector: 'full_name',
			sortable: true,
			right: false
		}

	]);

  const productorHandler = row  => {
    props.onSelectItem(row);
  }

  	useEffect(() => {
		if(props.productores.length){
			setBlocking(false);
		}
	}, [props.productores]);

	return (
		<BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
          <DataTable
		  	noHeader
            columns={columns}
            data={props.productores}
            Clicked
            noDataComponent={'Sin datos por mostrar'}
            customStyles={estiloGeneral}
            onRowClicked={productorHandler}
          />
		</BlockUi>
    );
  };
export default RiegosListadoProductores;