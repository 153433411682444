import React from 'react';
import { Link } from "react-router-dom";
import filterDataTables from '../../../helpers/filterDataTables';



import './HerramientasTablas.css'

export default function HerramientasTablas(props) {
  const { txtBusqueda ,datosTabla,urlRegistrar,columnasFiltrar,exportar,onFiltrar } = props;

  const handleChange = (e) => {
    onFiltrar(filterDataTables(datosTabla, e.target.value, columnasFiltrar));		  
  }

  const exportarRegistros = () => {
    exportar(datosTabla);
  }

  return (
    <>
        <div className="row margin_bottom">
                <div className="col-md-4">
                    <input onChange={(e) => {handleChange(e)}} type="text" className="form-control" placeholder={txtBusqueda} />
                </div>
                <div className="col-md-4">

                </div>
                <div className="col-md-4">
                    <div className="float-right">
                        {urlRegistrar.length > 0 && (
                        <Link className="btn btn-secondary margin_right" to={urlRegistrar}><i className="fa fa-plus" /> Agregar</Link>
                        )}
                      {exportar && (
                       <button className="btn btn-primary" onClick={() => exportarRegistros()} ><i className="fa fa-download" /> Descargar</button>
                      )}
                    </div>
                </div>
            </div>
    </>
  );
}